<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <div
        class="brand-logo"
        style="left: 30%"
      >
        <v-img
          :src="appLogoReset"
          max-height="100%"
          max-width="100%"
          alt="logo"
          contain
          class="justify-center"
        ></v-img>
      </div>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/ari.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pt-16"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Reset Password 🔒
                  </p>
                  <p class="mb-2">
                    Your new password must be different from previously used passwords
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="form"
                    @submit.prevent="handleFormSubmit"
                  >
                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="New Password"
                      placeholder="············"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline "
                      hide-details
                      :rules="[validators.required]"
                      class="mb-3"
                      :error-messages="errorMessages.password"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-text-field
                      v-model="confirmPassword"
                      outlined
                      :type="isConfirmPasswordVisible ? 'text' : 'password'"
                      label="Confirm Password"
                      placeholder="············"
                      :append-icon="isConfirmPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details
                      :rules="[validators.required]"
                      :error-messages="errorMessages.confirmPassword"
                      @click:append="isConfirmPasswordVisible = !isConfirmPasswordVisible"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      class="mt-4"
                      :disabled="isLoadingButton"
                      :loading="isLoadingButton"
                      type="submit"
                    >
                      Set New Password
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- back to login -->
                <v-card-actions class="d-flex justify-center align-center mt-2">
                  <router-link
                    :to="{path:'/login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Back to login</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import { required } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import store from '@/store'

export default {
  setup(props, context) {
    const isPasswordVisible = ref(false)
    const isConfirmPasswordVisible = ref(false)
    const password = ref('')
    const confirmPassword = ref('')
    const isLoadingButton = ref(false)
    const token = ref(context.root.$route.params.token)
    const form = ref(null)
    const errorMessages = ref({})
    const { router } = useRouter()

    const showSnackbar = snackbarData => {
      store.dispatch('snackbar/showSnackbar', snackbarData)
    }

    const handleFormSubmit = async () => {
      const isFormValid = form.value.validate()

      if (!isFormValid) return

      const data = {
        password: password.value,
        confirm_password: confirmPassword.value,
      }

      isLoadingButton.value = true
      await context.root.$services.AuthServices.resetPassword(token.value, data).then(
        res => {
          showSnackbar({
            color: 'success',
            text: res.data.result.message,
          })
          router.push('/login')
        },
        err => console.error(err),
      )

      isLoadingButton.value = false
    }

    return {
      isPasswordVisible,
      isConfirmPasswordVisible,
      password,
      confirmPassword,
      isLoadingButton,
      form,
      errorMessages,

      // themeConfig
      appName: themeConfig.app.name,
      appLogoReset: themeConfig.app.logoReset,
      appLogo: themeConfig.app.logo,

      // validation
      validators: {
        required,
      },

      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      handleFormSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
